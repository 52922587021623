import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"

const StyledSelect = styled.div`
  padding: 3rem;

  select {
    padding: 0.5rem;
    font-size: 1rem;
  }
`

const PodcastContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
  justify-content: space-around;
  padding: 2.5rem 0;
`

const StyledPodcasts = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 0.5rem 0.5rem;
  @media (max-width: 540px) {
    width: 150px;
    height: 150px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    border-radius: 10px;
    display: none;
    transition-duration: 0.5s;
    color: white;
    text-align: center;
    font-family: "Roboto", sans-serif;
    animation-name: fade-in;
    animation-duration: 0.5s;

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 100%;
      }
    }

    p {
      font-size: 0.9em;
      padding: 0.5rem;
    }
  }

  &:hover .overlay {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;

    @media (max-width: 540px) {
      width: 150px;
    }
  }
`

const ComicPodcasts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPodcast(filter: { category: { eq: "Comic Books" } }) {
        edges {
          node {
            title
            slug
            category
            description
            albumArt {
              fixed {
                width
                src
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <PodcastContainer>
        {data.allContentfulPodcast.edges.map(edge => {
          return (
            <Link to={`/podcast/${edge.node.slug}`}>
              <StyledPodcasts>
                <img src={edge.node.albumArt.fixed.src} alt={edge.node.title} />
                <div className="overlay">
                  <h3>{edge.node.title}</h3>
                  <p>{edge.node.description}</p>
                </div>
              </StyledPodcasts>
            </Link>
          )
        })}
      </PodcastContainer>
    </div>
  )
}

export default ComicPodcasts
