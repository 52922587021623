import React, { useState } from "react"
import Layout from "../components/layout"
import ImageHeader from "../images/podcast-submission-header.jpeg"
import SectionTitle from "../components/common/SectionTitle"
import PageImageHeader from "../components/common/PageImageHeader"
import PodcastDirectory from "../components/PodcastDirectory"
import styled from "styled-components"
import VideoGamePodcasts from "../components/VideoGamePodcasts"
import ProWrestlingPodcasts from "../components/ProWrestlingPodcasts"
import TvMoviesPodcasts from "../components/TvMoviesPodcasts"
import ComicPodcasts from "../components/ComicBooksPodcasts"
import TechPodcasts from "../components/TechPodcasts"
import PopCulturePodcasts from "../components/PopCulturePodcasts"

const StyledSelect = styled.select`
  margin: 2rem;
  font-size: 1.5em;
  padding: 0.5rem;
  border: 2px solid #7a9bba;
  border-radius: 15px;
  outline: none;
`

const PodcastsPage = () => {
  const [display, setDisplay] = useState({
    all: "",
    videoGames: "none",
    proWrestling: "none",
    tvAndMovies: "none",
    comicBooks: "none",
    tech: "none",
    popCulture: "none",
  })

  function handleChange(e) {
    if (e.target.value === "all") {
      setDisplay({
        all: "block",
        videoGames: "none",
        proWrestling: "none",
        tvAndMovies: "none",
        comicBooks: "none",
        tech: "none",
        popCulture: "none",
      })
    }
    if (e.target.value === "video-games") {
      setDisplay({
        all: "none",
        videoGames: "block",
        proWrestling: "none",
        tvAndMovies: "none",
        comicBooks: "none",
        tech: "none",
        popCulture: "none",
      })
    }
    if (e.target.value === "pro-wrestling") {
      setDisplay({
        all: "none",
        videoGames: "none",
        proWrestling: "block",
        tvAndMovies: "none",
        comicBooks: "none",
        tech: "none",
        popCulture: "none",
      })
    }
    if (e.target.value === "tv-movies") {
      setDisplay({
        all: "none",
        videoGames: "none",
        proWrestling: "none",
        tvAndMovies: "block",
        comicBooks: "none",
        tech: "none",
        popCulture: "none",
      })
    }
    if (e.target.value === "comics") {
      setDisplay({
        all: "none",
        videoGames: "none",
        proWrestling: "none",
        tvAndMovies: "none",
        comicBooks: "block",
        tech: "none",
        popCulture: "none",
      })
    }
    if (e.target.value === "tech") {
      setDisplay({
        all: "none",
        videoGames: "none",
        proWrestling: "none",
        tvAndMovies: "none",
        comicBooks: "none",
        tech: "block",
        popCulture: "none",
      })
    }
    if (e.target.value === "pop-culture") {
      setDisplay({
        all: "none",
        videoGames: "none",
        proWrestling: "none",
        tvAndMovies: "none",
        comicBooks: "none",
        tech: "none",
        popCulture: "block",
      })
    }
  }

  return (
    <Layout>
      <PageImageHeader imageHeader={ImageHeader} />
      <SectionTitle title="Podcasts" />
      <StyledSelect onChange={handleChange}>
        <option value="all">All Podcasts</option>
        <option value="video-games">Video Games</option>
        <option value="pro-wrestling">Pro Wrestling</option>
        <option value="tv-movies">Tv and Movies</option>
        <option value="comics">Comic Books</option>
        <option value="tech">Technology</option>
        <option value="pop-culture">Pop Culture</option>
      </StyledSelect>

      <div id="all-podcasts" style={{ display: display.all }}>
        <PodcastDirectory />
      </div>
      <div id="video-games" style={{ display: display.videoGames }}>
        <VideoGamePodcasts />
      </div>
      <div id="pro-wrestling" style={{ display: display.proWrestling }}>
        <ProWrestlingPodcasts />
      </div>
      <div id="tv-movies" style={{ display: display.tvAndMovies }}>
        <TvMoviesPodcasts />
      </div>
      <div id="comics" style={{ display: display.comicBooks }}>
        <ComicPodcasts />
      </div>
      <div id="video-games" style={{ display: display.tech }}>
        <TechPodcasts />
      </div>
      <div id="video-games" style={{ display: display.popCulture }}>
        <PopCulturePodcasts />
      </div>
    </Layout>
  )
}

export default PodcastsPage
